<template>
  <div>
    <app-info-messages :text="$t(translationKeys.infoMsg)"></app-info-messages>

    <div class="description text-16 text-grey-500 mb-20 mt-40">
      <h2>{{ $t('PROCESS_INFO.TITLE') }}</h2>
    </div>

    <div class="u flex flex-col w-full p-22 bg-grey-100 text-16">
      <div class="flex">
        <div class="field-size mr-20">
          <label class="text-gray-900 font-semibold">{{ $t('PROCESS_INFO.FIRST_FIELD') }}</label>
        </div>
        <div>
          <a
            class="text-blue-500 font-semibold cursor-pointer"
            target="_blanck"
            @click="downloadPdf()"
          >
            {{ document.name }}
          </a>
        </div>
      </div>
      <div v-for="(field, index) in fields" :key="index" class="flex pt-28">
        <div class="field-size mr-20">
          <label class="text-gray-900 font-semibold">{{ $t(field.label) }}:</label>
        </div>
        <div v-if="field.type === 'money'">
          <money v-model="field.description" v-bind="moneyFormat" disabled />
        </div>
        <div v-else class="field-description">{{ field.description }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import { saveFileAsPdf } from '@/shared/utils/fileDownload'
import { Money } from 'v-money'
import { money as moneyFormat } from '@/shared/constants/formats'

export default {
  name: 'AppProcessInfo',
  components: {
    Money,
    AppInfoMessages: () => import('@/components/AppInfoMessages'),
  },
  props: {
    translationKeys: { type: Object, required: true },
    document: { type: Object, required: true },
    fields: { type: Array, required: true },
  },
  data: () => ({
    moneyFormat,
  }),
  methods: {
    downloadPdf() {
      saveFileAsPdf(this.document.data, this.document.name)
    },
  },
}
</script>
<style lang="scss" scoped>
.field-size {
  width: 220px;
  min-width: 220px;
}

.field-description {
  white-space: pre-line;
  word-break: break-all;
}
</style>
